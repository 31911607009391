.topMianLogo {
  display: flex;
  .topMianLogoBg {
    display: none;
  }
  > .left {
    margin-top: 6rem;
    flex: 0.6;
    > h1 {
      > p:nth-of-type(1) {
        color: #02183a;
        font-size: 4.5rem;
        line-height: 3rem;
        margin-bottom: 1.5rem;

        font-weight: bolder;
      }
      > p:nth-of-type(2) {
        line-height: 4rem;

        font-size: 4.5rem;
        background: linear-gradient(90deg, #418dff, #f848af);
        width: fit-content;
        -webkit-background-clip: text;
        color: transparent;
        font-weight: bold;
        margin-bottom: 2rem;

        font-weight: bolder;
      }
    }
    > p:nth-of-type(1) {
      font-size: 1.5rem;
    }
    > .indicator {
      cursor: pointer;
      display: flex;
      margin-top: 2rem;
      margin-bottom: 2rem;
      > div {
        background: #f4f4f4;
        margin-right: 1rem;
        padding: 0.1rem 0.3rem;
        font-family: Popins;
        > span:nth-of-type(1) {
          margin-right: 10px;
          font-size: 1.2rem;

          > span {
            animation: moving 2s cubic-bezier(0.62, -0.91, 0.45, 1.97);
            // animation-delay: 1s;
            animation-fill-mode: both;
            cursor: pointer;
            display: inline-block;
            text-align: center;
            font-family: Caveat;
          }
        }
      }
    }
    > .btn {
      background-color: #03173a;
      color: #ffffff;
      height: 2.5rem;
      padding: 0.5rem 1.8rem;
      border-radius: 8px;
      border: none;
      margin-top: 1rem;
    }
    > .desc {
      display: flex;
      align-items: center;
      margin-top: 35px;
      > span:nth-of-type(1) {
        margin-left: 5px;
        margin-right: 5px;
        background: linear-gradient(90deg, #418dff, #f848af);
        -webkit-background-clip: text;
        color: transparent;
        font-weight: 400;
      }
      > span:nth-of-type(2) {
        font-weight: 300;
      }
    }
  }
  > .right {
    flex: 0.4;
    padding: 50px;
    height: 600px;
    position: relative;
    > img {
      position: absolute;
      width: 80% !important;
      opacity: 0;
      transition: opacity 7s cubic-bezier(0.63, -0.62, 0.81, -0.01);
    }
    > .floatRightTopOne {
      position: absolute;
      top: 9rem;
      right: 2rem;
      width: 100px;
      aspect-ratio: 1 / 1;
      background-color: #f4f4f4;
      img {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: opacity 3s ease-in-out;
      }
    }
    > .floatRightTopTwo {
      position: absolute;
      top: 13rem;
      right: 5rem;
      width: 100px;
      border: 2px solid #ffffff;
      aspect-ratio: 1 / 1;
      background-color: #f4f4f4;
      img {
        width: 100%;
        height: 100%;
        opacity: 0;
        position: absolute;
        transition: opacity 2s ease-in-out;
      }
    }
    .platforms {
      width: 240px;
      height: 100px;
      position: absolute;
      top: 23rem;
      right: 0rem;
      border-radius: 14px 14px 14px 14px;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: center;
      align-items: center;
      > div:nth-of-type(1) {
        margin-right: 1rem;
        img {
          height: 3rem;
        }
      }
      > div:nth-of-type(2) {
        > div:nth-of-type(1) {
          span {
            color: #ffffff;
            margin-right: 5px;
            display: inline-block;
          }
          img {
            vertical-align: middle;
          }
        }
        > div:nth-of-type(2) {
          margin-top: 10px;
          img {
            cursor: pointer;
            width: 1.5rem;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.bottomBtn {
  position: fixed;
  text-align: center;
  bottom: 2rem;
  width: 100%;
  div {
    background-color: #03173a;
    color: #ffffff;
    padding: 0.7rem 1.8rem;
    border-radius: 8px;
    width: 80%;
    z-index: 9999;
    margin: auto;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  .topMianLogo {
    > .left {
      > h1 {
        > p:nth-of-type(1) {
          font-size: 3.5rem;
        }
        > p:nth-of-type(2) {
          font-size: 3.5rem;
        }
      }
      > p:nth-of-type(1) {
        font-size: 1.2rem;
      }
      > .indicator {
        > div {
          margin-right: 10px;
          font-size: 0.9rem;
          > span:nth-of-type(1) {
            font-size: 0.9rem;
          }
        }
      }
      > .btn {
        margin-top: 0.5rem;
      }
      > .desc {
        display: flex;
      }
    }
    > .right {
      display: none;
    }
  }
}

@media (max-width: 899px) and (min-width: 576px) {
  .topMianLogo {
    // background-image: url("https://res-front.pumpsoul.com/prod-erasa/image/phone-header%2B(1).webp");
    // background-repeat: no-repeat;
    // background-position: right;
    // background-size: contain;
    // position: relative;
    position: relative;
    .topMianLogoBg {
      display: none;
    }
    · > .left {
      flex: 1;
      text-align: center;
      > h1 {
        > p:nth-of-type(2) {
          margin: auto;
          width: 20rem;
          line-height: 5rem;
        }
      }
      .indicator {
        justify-content: center;
      }
      .desc {
        display: flex;
        justify-content: center;
        margin-top: 1.8rem;
      }
    }
    > .right {
      display: none !important;
    }
  }
}

@media screen and (max-width: 576px) {
  .topMianLogo {
    position: relative;
    .topMianLogoBg {
      display: block;
      position: absolute;
      top: 50px;
      height: 100%;
      width: 100%;
      text-align: right;
    }
    > .left {
      margin-top: 2rem;
      flex: 1;
      text-align: center;
      > h1 {
        > p:nth-of-type(1) {
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
        > p:nth-of-type(2) {
          margin: auto;
          width: 15rem;
          font-size: 3rem;
          line-height: 3rem;
        }
      }
      > p:nth-of-type(1) {
        width: 15rem;
        margin: auto;
        font-size: 1.2rem;
        line-height: 1.4rem;
        margin-top: 1rem;
      }
      .indicator {
        display: block;
        > div {
          background-color: transparent;
          > span:nth-of-type(1) {
            > span {
              font-size: 1.5rem;
              font-family: Caveat;
            }
          }
        }
        .l1 {
          position: relative;
          margin-bottom: 1rem;
          &::before {
            content: ' ';
            display: block;
            height: 1rem;
            width: 7rem;
            margin: auto;
            background-color: #f4f4f4;
            // margin-bottom: 1rem;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            z-index: -1;
          }
        }
        .l2 {
          position: relative;
          margin-bottom: 1rem;

          &::before {
            content: ' ';
            display: block;
            height: 1rem;
            width: 11rem;
            margin: auto;
            background-color: #f4f4f4;
            transform: translateX(-50%);
            bottom: 0px;
            z-index: -1;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            z-index: -1;
          }
        }

        .l3 {
          position: relative;
          margin-bottom: 1rem;

          &::before {
            content: ' ';
            display: block;
            height: 1rem;
            width: 12rem;
            margin: auto;
            background-color: #f4f4f4;
            transform: translateX(-50%);
            bottom: 0px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            z-index: -1;
          }
        }
      }
      > .btn {
        width: 80%;
        height: 3rem;
        margin-top: 0;
      }
      > .desc {
        display: flex;
        justify-content: center;
        margin-top: 1.8rem;
      }
    }
    > .right {
      display: none;
    }
  }
}

@keyframes moving {
  from {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero {
  content-visibility: auto;
  contain: layout style paint;

  img {
    will-change: transform;
    transform: translateZ(0);
  }
}

.lazyContent {
  @media (prefers-reduced-motion: no-preference) {
    animation: fadeIn 0.5s ease-in;
  }
}

.topMianLogo {
}

