.left {
  margin-top: 6rem;
  flex: 0.6;
  > h1 {
    > p:nth-of-type(1) {
      color: #02183a;
      font-size: 3.5rem;
      line-height: 1;

      font-weight: bolder;
      margin-bottom: 0.2rem;
    }
    > p:nth-of-type(2) {
      line-height: 1;
      font-size: 3.5rem;
      background: linear-gradient(90deg, #418dff, #f848af);
      width: fit-content;
      -webkit-background-clip: text;
      color: transparent;
      font-weight: bold;
      margin-bottom: 2rem;

      font-weight: bolder;
    }
  }
  > p:nth-of-type(1) {
    font-size: 1.5rem;
  }
  > .indicator {
    cursor: pointer;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
    > div {
      background: #f4f4f4;
      margin-right: 1rem;
      padding: 0.1rem 0.3rem;
      font-family: Popins;
      > span:nth-of-type(1) {
        margin-right: 10px;
        font-size: 1.2rem;

        > span {
          animation: moving 2s cubic-bezier(0.62, -0.91, 0.45, 1.97);
          // animation-delay: 1s;
          animation-fill-mode: both;
          cursor: pointer;
          display: inline-block;
          text-align: center;
          font-family: Inter;
        }
      }
    }
    > .l1 {
      position: relative;
      > span:nth-of-type(1) {
        position: absolute;
        top: -25px;
        left: 5px;
        > span {
          font-size: 2.8rem;
        }
      }
      > span:nth-of-type(2) {
        padding-left: 1.9rem;
      }
    }
    > .l2 {
      position: relative;
      > span:nth-of-type(1) {
        position: absolute;
        top: -25px;
        left: 5px;
        > span:nth-of-type(1) {
          font-size: 2.8rem;
        }
        > span:nth-of-type(2) {
          font-size: 1.8rem;
        }
        > span:nth-of-type(3) {
          font-size: 1.8rem;
        }
      }
      > span:nth-of-type(2) {
        padding-left: 4.6rem;
      }
    }
  }
  > .btn {
    background-color: #03173a;
    color: #ffffff;
    height: 2.5rem;
    padding: 0.5rem 1.8rem;
    border-radius: 8px;
    border: none;
    margin-top: 1rem;
  }
  > .desc {
    display: flex;
    align-items: center;
    margin-top: 25px;
    > span:nth-of-type(1) {
      margin-left: 5px;
      margin-right: 5px;
      background: linear-gradient(90deg, #418dff, #f848af);
      -webkit-background-clip: text;
      color: transparent;
      font-weight: 400;
    }
    > span:nth-of-type(2) {
      font-weight: 300;
    }
  }
}
.bottomBtn {
  position: fixed;
  text-align: center;
  bottom: 2rem;
  width: 100%;
  button {
    background-color: #03173a;
    color: #ffffff;
    height: 3rem;
    padding: 0.5rem 1.8rem;
    border-radius: 8px;
    border: none;
    width: 80%;
    z-index: 9999;
  }
}

@media (max-width: 1024px) and (min-width: 900px) {
  .left {
    > h1 {
      > p:nth-of-type(1) {
        font-size: 3.5rem;
        width: fit-content !important;
      }
      > p:nth-of-type(2) {
        font-size: 3.5rem;
      }
    }
    > p:nth-of-type(1) {
      font-size: 1.2rem;
    }
    > .indicator {
      > div {
        margin-right: 10px;
        font-size: 0.9rem;
        > span:nth-of-type(1) {
          font-size: 0.9rem;
        }
      }
    }
    > .btn {
      margin-top: 0.5rem;
    }
    > .desc {
      display: flex;
    }
  }
}

@media (max-width: 899px) and (min-width: 576px) {
  .left {
    flex: 1;
    text-align: center;
    > h1 {
      > p:nth-of-type(1) {
        width: fit-content !important;
        margin: auto;
      }
      > p:nth-of-type(2) {
        margin: auto;
        margin-bottom: 1rem;
        width: 100%;
        line-height: 5rem;
      }
    }
    .indicator {
      justify-content: center;
    }
    .desc {
      display: flex;
      justify-content: center;
      margin-top: 1.8rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .left {
    margin-top: 2rem;
    flex: 1;
    text-align: center;
    > h1 {
      > p:nth-of-type(1) {
        font-size: 3rem;
        font-weight: bold;
        width: fit-content !important;
      }
      > p:nth-of-type(2) {
        margin: auto;
        font-size: 3rem;
        line-height: 3rem;
      }
    }
    > p:nth-of-type(1) {
      width: 15rem;
      margin: auto;
      font-size: 1.2rem;
      line-height: 1.4rem;
      margin-top: 1rem;
    }
    .indicator {
      display: block;
      > div {
        background-color: transparent;
        > span:nth-of-type(1) {
          > span {
            font-size: 1.5rem;
          }
        }
      }
      .l1 {
        position: relative;
        margin-bottom: 1rem;
        width: fit-content;
        margin: auto;
        > span:nth-of-type(1) {
          position: absolute;
          top: -25px;
          left: 5px;
          > span {
            font-size: 2.8rem;
          }
        }
        &::before {
          content: ' ';
          display: block;
          height: 1rem;
          width: 10rem;
          margin: auto;
          background-color: #f4f4f4;
          // margin-bottom: 1rem;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          z-index: -1;
        }
      }
      .l2 {
        position: relative;
        margin-bottom: 1rem;
        width: fit-content;
        margin: auto;
        position: relative;
        margin-top: 20px;

        &::before {
          content: ' ';
          display: block;
          height: 1rem;
          width: 11rem;
          margin: auto;
          background-color: #f4f4f4;
          transform: translateX(-50%);
          bottom: 0px;
          z-index: -1;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          z-index: -1;
        }
      }

      .l3 {
        position: relative;
        margin-bottom: 1rem;

        &::before {
          content: ' ';
          display: block;
          height: 1rem;
          width: 12rem;
          margin: auto;
          background-color: #f4f4f4;
          transform: translateX(-50%);
          bottom: 0px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0px;
          z-index: -1;
        }
      }
    }
    > .btn {
      width: 80%;
      height: 3rem;
      margin-top: 0;
    }
    > .desc {
      display: flex;
      justify-content: center;
      margin-top: 1.8rem;
    }
  }
}

@keyframes moving {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero {
  content-visibility: auto;
  contain: layout style paint;

  img {
    will-change: transform;
    transform: translateZ(0);
  }
}

.lazyContent {
  @media (prefers-reduced-motion: no-preference) {
    animation: fadeIn 0.5s ease-in;
  }
}

