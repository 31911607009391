.container {
  text-align: center;
  margin-top: 8rem;
  max-width: 1200px;
  margin: 0 auto;
  > h2:nth-of-type(1) {
    font-size: 1.4rem;
    > span:nth-of-type(1) {
      color: #f85db4;
      font-weight: bold;
    }
    > div:nth-of-type(1) {
      font-size: 2.5rem;
      font-weight: bold;
      color: #141313;
      margin-top: 1.5rem;
      margin-bottom: 1.8rem;
    }
  }

  > .main {
    // height: 500px;
    margin-top: 30px;
    // background: linear-gradient(
    //   0deg,
    //   #02183a 10%,
    //   rgba(17, 60, 172, 0.6) 60%,
    //   rgba(17, 60, 172, 0) 100%
    // );
    video {
      width: 100%;
      border-radius: 26px;
      box-shadow: 0px 4px 16px 0px rgba(32, 53, 108, 0.2);
    }
  }
  > .newMain {
    margin: auto;
    margin-top: 5rem;
    border-radius: 26px;
    background-image: linear-gradient(to bottom, #e1edff, #f9fbff);
    padding: 30px;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    > div:nth-of-type(1) {
      flex: 1;
      > h2 {
        font-weight: 700;
        font-size: 1.5rem;
      }
      > h3 {
        margin-top: 15px;
        color: #02183a;
        font-size: 0.9rem;

        line-height: 1.2rem;
      }
      img {
        margin-top: 40px;
        width: 80% !important;
      }
    }
    > ul:nth-of-type(1) {
      padding-top: 30px;
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      align-content: flex-start;
      li {
        background-color: #ffffff;
        border-radius: 10px;
        width: calc(50% - 15px);
        aspect-ratio: 145 / 90;
        // height: fit-content;
        padding: 10px;
        > img:nth-of-type(1) {
          height: 25px;
          width: 25px;
        }
        > h3 {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 5px;
        }
        > p {
          font-size: 14px;
          color: #979fad;
        }
      }
    }
  }
}
@media (max-width: 900px) and (min-width: 576px) {
  .newMain {
    margin-top: 3rem !important;
    display: block !important;
    width: 90% !important;
    padding: 10px !important;
    gap: 10px !important;
    width: calc(100% - 10px) !important;
    border-radius: 10px !important;
    padding-bottom: 20px !important;
    > div:nth-of-type(1) {
      padding-top: 20px;
    }
    > ul:nth-of-type(1) {
      padding-top: 10px !important;
      display: block !important;
      > li {
        box-shadow: 0px 4px 16px 0px rgba(32, 53, 108, 0.2);
        padding: 15px !important;
        width: 100% !important;
        margin-top: 15px;
        height: 150px !important;
        aspect-ratio: auto !important;
      }
    }

    > div:nth-of-type(1) {
      text-align: center;
      img {
        width: 100% !important ;
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .newMain {
    margin-top: 3rem !important;
    display: block !important;
    width: 90% !important;
    padding: 10px !important;
    gap: 10px !important;
    width: calc(100% - 10px) !important;
    border-radius: 10px !important;
    padding-bottom: 20px !important;
    > div:nth-of-type(1) {
      padding-top: 20px;
    }
    > ul:nth-of-type(1) {
      padding-top: 10px !important;
      display: block !important;
      > li {
        box-shadow: 0px 4px 16px 0px rgba(32, 53, 108, 0.2);
        padding: 15px !important;
        width: 100% !important;
        margin-top: 15px;
        height: 140px !important;
        aspect-ratio: auto !important;
      }
    }

    > div:nth-of-type(1) {
      text-align: center;
      img {
        width: 100% !important ;
      }
    }
  }
  .container {
    margin-top: 0rem;
    > h2:nth-of-type(1) {
      font-size: 1.4rem;
      > span:nth-of-type(1) {
        color: #f85db4;
        font-weight: bold;
      }
      > div:nth-of-type(1) {
        font-size: 2.5rem;
        font-weight: bold;
        color: #141313;
        margin-top: 0.8rem;
        line-height: 2rem;
      }
    }
    > .main {
      video {
        width: 90%;
        border-radius: 26px;
        box-shadow: 0px 4px 16px 0px rgba(32, 53, 108, 0.2);
      }
    }
  }
}

