.container {
  // background: linear-gradient(
  //   180deg,
  //   #02183a 10%,
  //   rgba(17, 60, 172, 0.6) 60%,
  //   rgba(17, 60, 172, 0) 100%
  // );
  height: fit-content;
  padding-bottom: 50px;
  padding-top: 60px;
  color: #ffffff;

  > h2 {
    color: #02183a;
    text-align: center;
  }
  > .main {
    display: flex;
    gap: 1rem;
    width: fit-content;
    margin: auto;
    margin-top: 4rem;
    > div {
      width: 280px;
      border: 1px solid #e3e3e3;
      background-color: #ffffff;
      color: #000000;
      border-radius: 8px;
      text-align: center;
      padding: 1rem;
      aspect-ratio: 280 / 350;
      img {
        height: 5rem;
        width: 5rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;
      }
      > h3 {
        font-weight: bold;
        font-size: 16px;
      }
      > div:nth-of-type(1) {
        padding: 0 1.5rem;
        line-height: 1.1rem;
        margin-top: 1rem;
        text-align: left;
      }
    }
  }
}
@media (max-width: 1204px) and (min-width: 537px) {
  .container {
    > .main {
      display: grid;
      grid-template-columns: 1fr 1fr; /* 定义两列，每列宽度相等 */
      grid-template-rows: 1fr 1fr; /* 定义两行，每行高度相等 */
      > div {
        width: calc(50vw - 2.8rem);

        aspect-ratio: 280 / 350;
      }
    }
  }
}

@media (max-width: 537px) {
  .container {
    // height: 950px;
    padding-top: 20px;
    padding-bottom: 40px;

    > .main {
      display: grid;
      grid-template-columns: 1fr 1fr; /* 定义两列，每列宽度相等 */
      grid-template-rows: 1fr 1fr; /* 定义两行，每行高度相等 */
      gap: 0.5rem;
      > div {
        // height: 250px;
        width: calc(50vw - 0.8rem);
        aspect-ratio: 280 / 350;
        > img {
          height: 45px;
          width: 45px;
          margin: 5px;
        }
        > h3 {
          width: 80%;
          margin: auto;
          line-height: 1.2rem;
        }
        > div:nth-of-type(1) {
          text-align: center;
          padding: 0 0.5rem;
        }
      }
    }
  }
}

