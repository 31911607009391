.header {
  text-align: center;
  margin-top: 2rem;
  color: #000000;
  font-size: 16px;
}
.main {
  width: 100%;
  display: flex;
  margin-top: 3rem;
  flex-wrap: nowrap;
  overflow: hidden;
  margin-bottom: 3rem;
  > img {
    margin-right: 3rem;
    height: 3.5rem;
    // width: 2rem;
    width: auto;
    object-fit: inherit;
  }
}
@media (min-width: 576px) {
  .header {
    // margin-top: 5rem;
  }
  .main {
    justify-content: center;
  }
}

