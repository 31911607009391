.mainBackContent {
  color: var(--app-text-color);
  padding: 0 0 2rem;
  // overflow-x: hidden;
  // background-color: var(--foreground-rgb);
  // text-align: center;

  // background-size: 100% auto;
  // background-repeat: no-repeat;
  // background-image: url('/images/layouts/background-icon.webp');
}

.mainContent {
  // color: var(--app-text-color);
  max-width: 1200px;
  // padding: 1rem;
  margin: 0 auto;
  // text-align: center;
}

